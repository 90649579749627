<script setup>
import Chart from 'primevue/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ref, computed, watch, onBeforeMount} from "vue";
import {HTTP} from "@/Plugins/axios";

const props = defineProps({
  chartType: {
    type: String,
    required: true
  },
  startDate: {
    type: String,
    required: true
  },
  endDate: {
    type: String,
    required: true
  }
})

const API_URL = process.env.VUE_APP_API_URL;
const myChart = ref();
const total = ref(0);
const endDate = ref(new Date());
const startDate = ref(new Date(new Date().setDate(endDate.value.getDate() - 7)));
const tabSelected = ref('expense');
const chartData = ref({
  labels: [],
  datasets: []
});

const getStartDate = () => {
  let date = startDate.value.toLocaleDateString().split('/')
  return date[2]+'-'+date[1]+'-'+date[0]
}

const getEndDate = () => {
  let date = endDate.value.toLocaleDateString().split('/')
  return date[2]+'-'+date[1]+'-'+date[0]
}

const setChartOptions =  {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltips: {
        enabled: true
      },
      legend: {
        position: 'bottom',
        cutout: '60%',
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          return (value*100 / sum).toFixed(2)+"%";
        },
        color: '#fff',
      }
    }
}

watch(props, () => {

  console.log(myChart.value.data)
})

const getChartData = () => {
  HTTP({
    url: `${API_URL}chart/${tabSelected.value}s?startDate=${getStartDate()}&endDate=${getEndDate()}&needed=true`,
    method: 'GET',
    data: {
      start_date: startDate.value,
      end_date: endDate.value,
    },
    headers: {
      Authorization:
          typeof localStorage !== 'undefined'
              ? localStorage.getItem('jwt')
                  ? `Bearer ${localStorage.getItem('jwt')}`
                  : ''
              : '',
    },
  })
      .then((response) => {
        console.log(response.data.data);
        chartData.value['labels'] = response.data.data.labels;
        total.value = response.data.data.total ?? 0;
        let data = {
          data: response.data.data.values,
          backgroundColor: response.data.data.colours
        }
        chartData.value['datasets'] =[data]
      })
      .catch((err) => {
        console.log(err);
      })
}

onBeforeMount(() => {
  getChartData();
});
</script>

<template>
  <div class="card flex flex-col justify-center w-full h-1/2">
    <div class="flex justify-center mt-4 font-bold text-2xl">
      Needed
    </div>
    <div class="flex justify-center mt-4 font-bold text-xl">
      $ {{ total }}
    </div>
    <Chart ref="myChart" type="doughnut" :data="chartData" :options="setChartOptions" :plugins="[ChartDataLabels]" class="sm:w-full h-full"/>
  </div>

</template>

<style scoped>

</style>